import React from "react"
import { Link } from "gatsby"

import DefaultLayout from "../layouts/default"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <DefaultLayout>
    <SEO title="404: Not found" />
    <div className="center">
      <h1>Not found 😢</h1>
      <p>The page you have request doesn't exist...</p>
      <span> &middot; </span>
      <Link to="/" className="home" className="home" title="Back Home">
        {" "}
        Home{" "}
      </Link>
      <span> &middot; </span>
    </div>
  </DefaultLayout>
)

export default NotFoundPage
